
import Login from '~/mixins/pages/login/login'

export default {
    mixins: [Login],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            currentPage: 'login',
        }
    },
    created() {
        this.changeLoginType(null)
    },
    methods: {
        onRegisterNow() {
            this.$router.push('/register')
        },
        onClose() {
            this.$router.push('/')
        },
        changeLoginType(type) {
            this.loginType = type || this.loginType
            if (this.loginType === 'username') {
                this.isRequireRule = false
                if (!this.form.player_id) {
                    const username = this.$cookie.get('un')
                    if (!username) {
                        return
                    }
                    this.form.player_id = username
                }
            } else if (this.loginType === 'phone') {
                if (!this.form.phone) {
                    const phoneNumber = this.$cookie.get('pn')
                    if (!phoneNumber) {
                        return
                    }
                    this.form.phone = phoneNumber
                }
            }
        },
    },
}
