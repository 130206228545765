
import formatInputCurrencyAmount from '~/mixins/common/format-input/format-input-currency-amount'

export default {
    mixins: [formatInputCurrencyAmount],
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => {},
        },
        dense: {
            type: String,
            default: '',
        },
        outlined: {
            type: String,
            default: '',
        },
        hideDetails: {
            type: [String, Boolean],
            default: false,
        },
        // eslint-disable-next-line vue/require-prop-types
        errorMessages: {
            default: null,
        },
    },
}
