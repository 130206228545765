import { render, staticRenderFns } from "./theme-2.vue?vue&type=template&id=0bb8cfa2"
import script from "./theme-2.vue?vue&type=script&lang=js"
export * from "./theme-2.vue?vue&type=script&lang=js"
import style0 from "./theme-2.vue?vue&type=style&index=0&id=0bb8cfa2&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsDownloadBanner: require('/app/components/Commons/DownloadBanner.vue').default,CommonsMaintenance: require('/app/components/Commons/Maintenance.vue').default,CommonsChangeLanguageLoading: require('/app/components/Commons/ChangeLanguageLoading.vue').default,Theme2ConfirmDepositDialog: require('/app/components/Theme2/ConfirmDepositDialog.vue').default,Theme2FloatingIcon1: require('/app/components/Theme2/FloatingIcon1.vue').default,Theme2FloatingIcon2: require('/app/components/Theme2/FloatingIcon2.vue').default,Theme2AgentFloatingIcon: require('/app/components/Theme2/AgentFloatingIcon.vue').default,Theme2ImageBackground: require('/app/components/Theme2/ImageBackground.vue').default,Theme2HoldBalanceDialog: require('/app/components/Theme2/HoldBalanceDialog.vue').default,CommonsThemeMode: require('/app/components/Commons/ThemeMode.vue').default})
