import { render, staticRenderFns } from "./DisplayBankQr.vue?vue&type=template&id=a348d03a&scoped=true"
import script from "./DisplayBankQr.vue?vue&type=script&lang=js"
export * from "./DisplayBankQr.vue?vue&type=script&lang=js"
import style0 from "./DisplayBankQr.vue?vue&type=style&index=0&id=a348d03a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a348d03a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default})
