
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import download from '~/mixins/pages/download'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },

    mixins: [download],

    props: {
        operatingSystem: {
            type: String,
            default: '',
        },
    },
}
